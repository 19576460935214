import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import styled from "styled-components";
import Navigation from "./components/Navigation";
import { Information, AgendaContainer, Archive, CallForPapers} from "./components";

const Container = styled.div`
  max-width: 1200px;
  margin: 0px auto;
  padding: 0px 14px;
`;

const HeaderText  =styled.div`
text-align: center;
margin: 20px 0 50px 0;
`;

const Headline = styled.p`
 color: #67DEFB;
  font-weight: 300;
  text-transform: uppercase;
  margin: 0px;
  letter-spacing: 0.01875rem;
  font-size: 16px;
  line-height: 1.125rem;
  margin-bottom: 10px;

  @media (min-width: 767px) {
    letter-spacing: 0.04375rem; 
    font-size: 18px; 
    line-height: 1.25rem;
  }

  @media (min-width:1023px) {
    letter-spacing: 0.0625rem; 
    font-size: 20px;
    line-height: 1.625rem;
  }
    
`;
const NewApp = ()=> {
  return(
    <Container>
      <Router>
        <Navigation/>
        <HeaderText>
        <Headline>Velkommen til</Headline>
        <h1> Konferanse om teknologi og innovasjon - av og for selskapene i Jonsvollskvartalet</h1>
        </HeaderText>
        <Routes>
          <Route path="/" exact element={<Information />} />
          <Route path="/info" exact element={<Information />} />
          <Route path="/callforpapers" exact element={<CallForPapers />} />
          <Route path="/program/:yearparam" element={<AgendaContainer />} />
          <Route path="/arkiv" element={<Archive />} />
          <Route path="/404" element={<h1>Oops! Siden ble ikke funnet.</h1>} />
          <Route path="*" element={<h1>Oops! Siden ble ikke funnet.</h1>} />
        </Routes>
      </Router>
  </Container>
  )
};

export default NewApp;