import React from "react";
import styled from "styled-components";

const SpeakerInformation = styled.ul`
  font-size: 1.3rem;
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    margin-bottom: 1rem;
  }
`;

const CallForPapers = () => (
  <SpeakerInformation>
    <h2>Call for papers</h2>
    <i>
      <p>Kjære lyntalere ❤️ </p>
      <p>Tusen takk til alle som har lyst å bidra på årets Plask! </p>
      <p> Deres bidrag er det som gjør Plask spennende!</p>
    </i>

    <br />

    <h3>📝 Påmelding:</h3>
    <ul>
      <li>
        Du finner påmeldingsskjema her: <a href="https://forms.gle/ey4n7r8exPxh6FKi9">Påmeldingsskjema</a>
      </li>
      <li>
        Frist for å sende inn forslag: TBA
      </li>
    </ul>

    <h3>⚡ Lightning talks</h3>
    <p>
      Vi ønsker å høre fra alle som har noe å dele, enten du brenner for et nytt verktøy,
      vil fremheve gode metodikker, eller ønsker å inspirere andre med en kreativ løsning.
    </p>
    <p>
      Formatet er som vanlig lyntaler som er 10 eller 25 minutter.
      Har du flere idéer? Du kan gjerne sende inn flere forslag. :D
    </p>

    <h3>👋 Hvorfor delta?</h3>
    <p>
      Dette er en intern konferanse, og det blir masse kjente fjes! Det er en super mulighet til å øve på
      presentasjonsteknikk, få eksoneringsterapi i trygge omgivelser, eller dele noe kult med et engasjert publikum.
    </p>

    <h3>✍️ Forberedelser</h3>
    <p>
      For de som ønsker det, tilbyr vi et kurs i presentasjonsteknikk uken før konferansen.
      Vi hjelper deg også gjerne med å spisse budskapet ditt eller forberede presentasjonen.
    </p>

    <h3>🙋 Har du spørsmål?</h3>
    <p>Ta kontakt med oss på slack kanalen #plask</p>
  </SpeakerInformation>
);

export default CallForPapers;
