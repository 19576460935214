import React from "react";
import styled from "styled-components";

const InfoList = styled.ul`
  font-size: 1.3rem;
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    margin-bottom: 1rem;
  }
`;

const Information = () => (
  <>
    <InfoList data-testid="infolist">
      <li>
        <h2>
          <span role="img" aria-label="kalender">
            🗓
          </span>{" "}
          Dato
        </h2>
        PLASK blir 13.mars 2025.<br/>
      </li>
      <br />
      <li>
        <h2>
          <span role="img" aria-label="tidtaker">
            ⏲
          </span>{" "}
          Program
        </h2>
        <p>
          🗣️ Det blir talks 10-16
        </p>
        <p>
          🌊 Mageplask* starter 16:00 
        </p>
        <p>*MagePlask er det sosiale eventet som skjer etter PLASK. Her inviterer vi til mat og drikke, samt gode diskusjoner av dagens læring.</p>
      </li> 
      <br />
      <li>
        <h2>
          <span role="img" aria-label="kalender">
            📢
          </span>{" "}
          Del din lidenskap for teknologi og innovasjon
        </h2>
        <p>Vi vil høre din historie!</p><a href="/callforpapers">Meld deg som speaker</a><br/>
      </li>
      <br />
      <li>
        <h2>
          <span role="img" aria-label="telefon">
            📞
          </span>{" "}
          Slack
        </h2>
        Ta kontakt på Slack i kanalen #plask om du har spørsmål.
      </li>
      <br />
      <li>
        <h2>
          <span role="img" aria-label="telefon">
          👋
          </span>{" "}
          Påmelding til konferansen
        </h2>
        <p style={{textDecoration: 'underline', }}><i>Informasjon om påmelding kommer snart!</i></p>
        {/* <p><a href="https://forms.gle/CZka4kDHuqvYs6A28">Meld deg på her!</a></p> */}
      </li>
      <br />
      <li>
        <h2>
          <span role="img" aria-label="kamera">
            📷
          </span>{" "}
          Bilder
        </h2>
        Se bildene fra forrige Plask-konferanse og følg oss på Instagram <a href="https://www.instagram.com/plask_konferanse/">@plask_konferanse</a>
      </li>
      <br />
    </InfoList>
  </>
);

export default Information;
