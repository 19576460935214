import { useState } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { FiMenu } from "react-icons/fi";
import { MdClose } from "react-icons/md";
import Logo from "./Logo/Logo";

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
`;

const Nav = styled.nav`
  margin-top: 24px;
`;

const BurgerMenu = styled.button`
  margin-top: 15px;
  cursor: pointer;
  background: transparent;;
  border: none;
  z-index: 1000;
  position: relative;
  @media (min-width: 767px) { 
    display: none;
  }
`;

const Ul = styled.ul`
  display: ${(props) => props.menu ? "flex" : "none"};
  background-color: white;
  position: absolute;
  flex-direction: column;
  align-items: center;
  list-style-type: none;
  right: 0;
  width:100%;
  z-index: 1;
  padding: 0;
  top:0;
  margin: 0;
  padding-top: 45px;
  
  li {
    margin-bottom:20px;
    margin-top:10px;
  }
 
  a {
    text-decoration: none;
    font-size: 18px;
    color: #222f49;
    :hover{
      color: #567592; 
    }
  }
  @media (min-width: 767px) { 
    display: flex;
    flex-direction: row;
    position: unset;
    background-color: #FCF8F3;
    padding-top: 20px;
    
    li:not(:last-child) {
    margin-right: 50px;
    }
  }
`;

const StyledLogo = styled(Logo)`
  width: 200px;
`;

const Navigation = () => {
  const [showMenu, setShowMenu] = useState(false);

  return (
      <Flex>
        <NavLink to="/"><StyledLogo/></NavLink>
        <Nav>
          <BurgerMenu aria-label="Open the menu" aria-hidden="true" color="black" size="2rem" onClick={() => setShowMenu(!showMenu)}>
          
          {showMenu ? (
            <MdClose style={{ width: '32px', height: '32px' }} />
          ) : (
            <FiMenu
              style={{
                width: '32px',
                height: '32px',
              }}
    />
  )}
  </BurgerMenu>
          
          <Ul menu={showMenu}>
            <li>
              <NavLink to="/info" onClick={() => setShowMenu(false)}>
                Praktisk info
              </NavLink>
            </li>
            <li>
              <NavLink to="/callforpapers" onClick={() => setShowMenu(false)}>
                Call for papers
              </NavLink>
            </li>
            <li>
              <NavLink to="/program/2025" onClick={() => setShowMenu(false)}>
                Programmet 2025
              </NavLink>
            </li>
            <li>
              <NavLink to="/arkiv" onClick={() => setShowMenu(false)}>
                Arkiv
              </NavLink>
            </li>
          </Ul>
        </Nav>
      </Flex>
  );
};

export default Navigation;